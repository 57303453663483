import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./world.css";

import world from "../../Images/world.png";
import graph from "../../Images/graph.png"


const World = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="WSuper">
      <div className="WContainer">
        <div
          className="WTitle"
          data-aos="fade-down"
          data-aos-easing="ease-in-out"
          data-aos-delay="100"
        >
          <h2>Cranky, the banana lover bat</h2>
        </div>
        <div className="WText">
          <div
            className="WTParagraph"
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-delay="1000"
          >
            <iframe width="560" height="315" src="https://www.youtube.com/embed/SoAh6jSyXh8?si=u4wtgsolMisqsn5Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>          </div>
          <div
            className="WTParagraph"
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-delay="2000"
          >
            <p>
            This is the goofiest, cartooniest, cutest animal I’ve ever seen in real life 
    	      and now on Solana too.
            </p>
          </div>
        </div>
        <div
          className="WImage"
          data-aos="fade"
          data-aos-easing="ease-in-out"
          data-aos-delay="500"
        >
          {/* <img src={world} alt="" /> */}
        </div>
      </div>
    </div>
  );
};

export default World;
